<template>
    <v-row class="centro">
      <v-col class="align-center">
        <v-data-table
          dense
          :headers="headers"
          :items="usuarios"
          item-key="id"
          height="auto"
          locale="pt-BR"
          class="elevation-10 mb-10"
          :search="search"
        >
          <template v-slot:item.id_cliente="{ item }" >
            {{ buscaCliente(item) }}
          </template>

          <template v-slot:top>
            <v-toolbar id="tb" flat>
              <svg-icon height="30px" width="30px" type="mdi" :path="icone"></svg-icon>
              <v-toolbar-title>Cadastro de Usuários</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <svg-icon class="mt-2" type="mdi" :path="pesquisar"></svg-icon>
              <v-text-field
                v-model="search"
                label="Pesquisar"
                class="ml-1 mt-7 mr-7"
              ></v-text-field>
              <v-btn
                color="black"
                dark
                class="mb-2"
                @click="newItem()"
              >
                Novo Usuário
              </v-btn>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Tem certeza que deseja excluir o usuário
                    {{editedItem.nome}}?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                      Excluir
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:no-data>
            Ainda não existem dados cadastrados
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click="editItem(item)" class="mr-4" icon color="black">
              <svg-icon type="mdi" :path="editar"></svg-icon>
            </v-btn>
            <v-btn @click="deleteItem(item)" class="mr-4" icon color="black">
              <svg-icon type="mdi" :path="excluir"></svg-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
</template>
  
<script>
  import svcUsuarios from "../../services/svcUsuarios";
  import svcClientes from "../../services/svcClientes";
  import SvgIcon from '@jamescoyle/vue-icon';
  import { mdiPencil, mdiDelete, mdiMagnify, mdiAccountGroup } from '@mdi/js';
  import { decriptar, encriptar } from "../../config/cripto";
  
  
  export default {
    components: {
          SvgIcon
      },
    data() {
      return {
        editar: mdiPencil,
        excluir: mdiDelete,
        pesquisar: mdiMagnify,
        icone: mdiAccountGroup,
        search: "",
        clientes: [],
        usuarios: [],
        headers_master: [
          { text: "ID", align: "start", sortable: true, value: "id" },
          { text: "Cliente", value: "id_cliente" },
          { text: "Nome", value: "nome" },
          { text: "CPF", value: "cpf" },
          { text: "Email", value: "email" },
          { text: "Ações", align: "center", value: "actions", sortable: false },
        ],
        headers_cliente: [
          { text: "ID", align: "start", sortable: true, value: "id" },
          { text: "Nome", value: "nome" },
          { text: "CPF", value: "cpf" },
          { text: "Email", value: "email" },
          { text: "Ações", align: "center", value: "actions", sortable: false },
        ],
        headers: [],
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          id: "",
          id_cliente: "",
          nome: "",
          cpf: "",
          email: "",
          senha: "",
        },
        defaultItem: {
          id: "",
          id_cliente: "",
          nome: "",
          cpf: "",
          email: "",
          senha: "",
        },
        id_cliente_logado: "",
        isSelecting: false
      };
    },
  
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    buscaCliente(item) {
      var cliente = this.clientes.filter(d => d.id === item.id_cliente);
      return cliente[0].nome;
    },

    listaClientes() {
      svcClientes
        .listar()
        .then((resp) => {
          this.clientes = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    lista() {
      svcUsuarios
        .listar(this.id_cliente_logado)
        .then((resp) => {
          this.usuarios = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    newItem() {
      this.$router.push("/usuarios/new");
    },

    editItem(item) {
      this.$router.push("/usuarios/edit/" + encriptar(item.id));
    },

    deleteItem(item) {
      this.editedIndex = this.usuarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      svcUsuarios
        .excluir(this.editedItem.id)
        .then((resp) => {
          if (resp.status == 200) {
            this.lista();
          } else {
            alert("Erro excluindo um usuário.");
          }
        })
        .catch((error) => {
          alert(error);
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

  mounted() {
    this.id_cliente_logado = decriptar(localStorage.getItem("cdi"));
    if (decriptar(localStorage.getItem("udi")) == "0") {
      this.headers = this.headers_master;
    } else {
      this.headers = this.headers_cliente;
    }
    this.listaClientes();
    this.lista();
  },
};
</script>
